import React from 'react';

// const ProductLayout = dynamic(() => import("../components/Layout/ProductLayout"));
import ProductLayout from "../components/Layout/ProductLayout";
import { hasProperty } from '../utils/validate';

const NotFoundResponse = ({ data = { title: null, button: null} }) => {
	return (
		<>
			<ProductLayout
				nav={hasProperty(data, 'nav') ? data.nav.header.links : false}
				logo={hasProperty(data, 'nav') ? data.nav.header.logos.general : null}
				footer={hasProperty(data, 'nav') ? data.nav.footer : false}
			>
				<div className="errorBody">
					<div>
						<img
							className="responsiveImage main404"
							src="/static/img/404image.jpg"
						/>
						<div className="main404Page">
							<p className="white-text">
								{data.title
									|| "Oops...it seems that page does not exist!"}
							</p>
							<a
								title="Back"
								href="/"
								className="whiteRedBtn waves-effect waves-light btn-large"
							>
								{data.button ? data.button : "Go Back"}
							</a>
						</div>
					</div>
				</div>
			</ProductLayout>
		</>
	);
};

export default NotFoundResponse;