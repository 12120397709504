import dynamic from "next/dynamic";
import Icons from "../Shared/IconsImport";

const ProductHeader = dynamic(import("../Header/ProductHeader"));
const GeneralFooter = dynamic(import("../Footer/GeneralFooter"));

type Props = { includeIconImport?: boolean, nav: any, logo: any, children: React.ReactNode, footer: any, root?: string };

export default function ProductLayout(
	{ nav, logo, children, footer = false, includeIconImport = true }: Props
) {
	return (
		<>
			{/* This allows the caller of the layout to determine if icons get imported */}
			{includeIconImport && <Icons />}
			<div
				className="layout"
			>
				<ProductHeader
					nav={nav}
					logo={logo}
				/>
				{children}
				{footer !== false && (
					<GeneralFooter footer={footer} />
				)}
			</div>
		</>
	);
}